import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "How to Build an Effective Enterprise Website"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />



      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><h2>5 Steps to Building an Strong Enterprise Website</h2>
                <p>At DecodeUp, we have many years of experience building websites and creating digital strategies for clients from different industries and stages of their business. We know the work that goes into every project and despite the quirks and challenges, designing an enterprise website is a highly involved process requiring initial investment and strategy.</p>
                <p>Enterprise websites can comprise thousands of pages of content, or even hundreds of microsites, and overhauling them can become overwhelming very fast. Building an enterprise site from scratch is challenging too and requires careful planning for a range of situations that may not have been considered yet.</p>
                <p>Working with enterprise clients for years has given us an insider perspective into what works and what doesn’t. Below we share some strategies that make a difference.</p>
                <p>Highlight User-focused Design</p>
                <p>Many companies fall into the trap of prioritizing a story they want to tell instead of displaying information their users need to see and hear. Yes, your website is the face of your company online, but it needs to do more than just look good. At the end of the day, the website exists for a single purpose: to serve your users. Every decision you make about the website should be based on whether or not it will benefit users.</p>
                <p>It’s helpful to ask this question often during the strategy and planning phases, to see how you can exceed users’ expectations, make their experience enjoyable, and provide them with a positive impression of your company.</p>
                <ul>
                  <li>Understand Users &amp; Stakeholders</li>
                </ul>
                <p>To obtain quality user-focused design, you have to understand your audience first. At the enterprise level, this audience is likely wide-ranging, composed of people all over the country or world with lots of tasks they are coming to your site for. While you probably can’t speak to all of them, you can isolate the most important segments of your audience in order to design the site to address their needs.</p>
                <p>Internal stakeholders play a big role in determining site architecture and design direction. Stakeholders from different departments in your organization can bring valuable knowledge about customers and their needs. Web designers can benefit from the views of marketing, sales, operations and human resources, for example, which will all differ. Getting widespread insight will give you a holistic understanding of where your site’s greatest opportunities.</p>
                <ul>
                  <li>Structure Your Content</li>
                </ul>
                <p>At the enterprise level, content needs are far from simple. There are lots of user groups to reach and content must be tailored to each of their unique needs.</p>
                <p>As you formulate content, aim to achieve a balance between breadth and depth. Content that is too broad will lack meaning and a strategy that’s too narrow could alienate users who don’t see their needs reflected. Any form of extremes could cost you valuable time and sales.</p>
                <p>Equally, you need to present your content to users in a way that holds their attention. Attention spans are getting shorter all the time, you can’t assume people will read or watch your whole content. Good content layouts make text easy to skim and highlight the biggest takeaways.</p>
                <ul>
                  <li>Always prioritize mobile</li>
                </ul>
                <p>If you own a business with a website you likely know that mobile experiences are important to users, more so than the desktop experience. Mobile access is core to your brand’s search visibility.</p>
                <p>For users, if they can’t access a brand on mobile they assume the brand doesn’t care or is stuck in the past. This can erode user trust and lower site conversions, ruining your brand’s image.</p>
                <p>Designing mobile experiences and optimizing your digital spaces for mobile can be a complicated journey, especially at the enterprise level where content requires layers of navigation. But prioritizing mobile is essential to keeping users happy and has a positive impact on sales in both B2B and B2C settings.</p>
                <ul>
                  <li>Building better enterprise experiences</li>
                </ul>
                <p>Though designing effective digital experiences for the enterprise presents unique challenges for both scale and complexity, you can achieve it with careful planning.</p>
                <p>Remember to focus on the user experience, return to the important questions, and verify that regardless of scale you are building something engaging and delightful.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage5($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
       
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`